import React from "react";
import { graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import SiteHeader  from '../components/SiteHeader';

// styles
const pageStyles = {
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const FillingTitle = styled.h1`
  font-family: 'Fredoka One', cursive;
  font-size: 5rem;
  margin: 0;
`;

const FillingType = styled.h2`
  font-family: 'Fredoka One', cursive;
  font-size: 2.5rem;
  margin: 0;
`;

const BurgerMakerLayout = styled.div`
  display: grid;
  // grid-gap: 0.25rem;
`;

const stripBgVariants = {
  'pink': `
    background: hotpink;
    color: black;
  `,
  'pink-strips': `
    background: hotpink;
    background: repeating-linear-gradient(-45deg, hotpink, hotpink 20px, black 22px, black 23px);
  `,
  'black': `
    background: black;
  `,
  'black-strips': `
    background: black;
    background: repeating-linear-gradient(45deg, black, black 20px, hotpink 22px, hotpink 23px);
  `,
  'yellow': `
    background: yellow;
    color: black;
  `,
  'yellow-strips': `
    background: yellow;
    background: repeating-linear-gradient(45deg, yellow, yellow 20px, black 22px, black 23px);
    color: black;
  `
};

const Strip = styled.div`
  color: white;
  ${({ variant }) => stripBgVariants[variant]}
  padding: 2rem;

  @media only screen and (min-width: 992px) {
    padding: 2rem 4rem;
  }
`;

const FillingLink = styled(Link)`
  display: grid;
  grid-template-rows: 1fr auto;
  justify-content: center;
  align-items: center;
  color: black;
  text-decoration: none; 
  font-family: 'Fredoka One', cursive;
  background: white;
  text-align: center;
  box-shadow: 0 0 0.125rem 0.125rem black;
  border-radius: 1rem;
  transition: background 0.5s;

  &:hover {
    background: yellow;
  }
`;

const FillingList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media only screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const IngredientsImg = styled.img`
  padding: 1rem 1rem 0 1rem;
  max-width: 100%;
  box-sizing: border-box;
`;

// markup
const IndexPage = ({ data }) => {

  return (
    <main>
      <title>BurgerBot.net</title>
      <BurgerMakerLayout>
      <SiteHeader />
      <Strip variant="yellow">
        <FillingType>All</FillingType>
        <FillingTitle>Fillings</FillingTitle>
      </Strip>
      <Strip variant="pink-strips">
        <FillingList>
          {data.fillings.edges.map((filling) => {
            return (
              <FillingLink key={filling.node.frontmatter.slug} to={`/filling/${filling.node.frontmatter.slug}`}>
                <IngredientsImg
                  src={`/images/ingredients/${filling.node.frontmatter.slug}.png`}
                  alt={filling.node.frontmatter.title}
                  objectFit="contain"
                />
                <h3>{filling.node.frontmatter.title}</h3>
              </FillingLink>
            )
          })}
        </FillingList>
      </Strip>
      </BurgerMakerLayout>
    </main>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    fillings: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {frontmatter: {type: {eq: "filling"}, published: {ne: false}}}
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            type
            slug
            title
          }
        }
      }
    }
  }
`



